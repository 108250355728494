.root:not(:last-child) {
    margin-bottom: 1rem;
}

.checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    margin: 0rem 0.5rem 0rem 0rem;

    height: 1.5rem;
    width: 1.5rem;
    border: 2px solid #979797;

    border-radius: 3px;

    cursor: pointer;
    outline: none;
}

.checkbox:checked {
    border-color: #006be7;
    background-color: #006be7;
    &::before {
        content: "";
        height: 6px;
        width: 9px;
        border-left: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        transform: rotate(-45deg);
        margin-top: -4px;
    }
}
